import { NextSeo, NextSeoProps } from 'next-seo'

interface SeoProps extends NextSeoProps {
  path: string
  site_url?: string
  title?: string
  description?: string
  image?: string
}

//   url: '/assets/images/og-image-01.png',
//   width: 800,
//   height: 600,
//   alt: 'Og Image Alt',
// }
const Seo = ({ title, description, path, site_url, image }: SeoProps) => {
  // check if image is an array or string
  const images = []
  if (typeof image === 'string') {
    images.push({
      url: image,
      width: 800,
      height: 600,
      alt: 'Og Image Alt',
    })
  }

  return (
    <NextSeo
      title={title}
      description={description}
      openGraph={{
        url: `${site_url}/${path}`,
        title,
        description,
        images: [...images],
      }}
    />
  )
}

export const reformStoreFromHeaders = (ctx: any) => {
  const storeDetailsHeader = ctx?.req?.headers?.['x-store-details']
  if (!storeDetailsHeader) return null
  const storeDetails = JSON.parse(storeDetailsHeader as string)
  const { name, id, theme, description, imageUrl, siteUrl, themeColor } =
    storeDetails
  const fullDescription = description ? `${name} - ${description}` : name
  const ogImageWidth = '1200'
  const ogImageHeight = '630'

  const ogImageUrl = `${
    imageUrl ?? ''
  }-/preview/${ogImageWidth}x${ogImageHeight}/`
  const storeDetailsWithMeta = {
    name,
    id,
    theme,
    description: fullDescription,
    imageUrl: imageUrl ?? null,
    siteUrl,
    themeColor,
    ogImageUrl,
    ogImageWidth,
    ogImageHeight,
  }
  return storeDetailsWithMeta
}

export default Seo
