import Seo, {reformStoreFromHeaders} from '@components/seo/seo'
import Layout, { useFindHomePage } from '@components/layout/PageLayout'
import { GetServerSidePropsContext } from 'next'


function Home({ pageProps} :any ) {
  const HomePage = useFindHomePage()
  const shop = pageProps?.storeDetails
  return (
    <>
      <Seo
        title={`${shop?.name} | Online Store`}
        description={`Welcome to ${shop?.name}; ${shop?.description}`}
        path="/"
        site_url={shop?.siteUrl}
        image={shop?.imageUrl}
      />
      <HomePage />
    </>
  )
}
Home.Layout = Layout

export const getServerSideProps = async (ctx: GetServerSidePropsContext) => {
  const storeDetails = reformStoreFromHeaders(ctx)
  return { props: {
      pageProps : {
        storeDetails
      }
    } 
  }
}

export default Home
